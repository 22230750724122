<template>
  <header
    id="header"
    v-click-outside="() => (mobileNavMenu = false)"
    v-page-scroll="() => (mobileNavMenu = false)"
    class="header"
  >
    <div
      class="btn-hamburger"
      @click="mobileNavMenu = !mobileNavMenu"
    >
      <!-- use .btn-humburger-active to open the menu -->
      <div class="top bar" />
      <div class="middle bar" />
      <div class="bottom bar" />
    </div>

    <!-- use .navbar-open to open nav -->
    <nav
      class="navbar"
      :class="{ 'navbar-open': mobileNavMenu }"
    >
      <ul>
        <li
          v-if="getConfirmedUser"
          class="navbar-user"
        >
          <a
            v-click-outside="() => (userDropdownOpen = false)"
            @click.prevent="userDropdownOpen = !userDropdownOpen"
          >
            <AppAvatarImg
              v-if="authUser?.avatar"
              class="avatar-small"
              :src="authUser?.avatar"
              :alt="`profile picture`"
            />
            <AppAvatarImg
              v-else
              class="avatar-small"
              src="/user-placeholder.png"
              :alt="`profile picture`"
            />
            <span>
              <span v-if="authUser?.name">{{ authUser.name.first }} {{ authUser.name.last }}</span>
              <img
                class="icon-profile"
                src="../assets/svg/arrow-profile.svg"
                alt="Arrow"
                style="margin-left: 5px"
              >
            </span>
          </a>

          <!-- dropdown menu -->
          <!-- add class "active-drop" to show the dropdown -->
          <div
            id="user-dropdown"
            :class="{ 'active-drop': userDropdownOpen }"
          >
            <div class="triangle-drop" />
            <ul class="dropdown-menu">
              <li class="dropdown-menu-item">
                <router-link :to="{ name: 'Profile' }">
                  View Profile
                </router-link>
              </li>
              <li class="dropdown-menu-item">
                <a @click.prevent="signOut">Sign Out</a>
              </li>
            </ul>
          </div>
        </li>
        <li
          v-if="!getConfirmedUser"
          class="navbar-item"
        >
          <router-link :to="{ name: 'AuthLogin' }">
            Sign In
          </router-link>
        </li>
        <li
          v-if="getConfirmedUser"
          class="navbar-mobile-item"
        >
          <router-link :to="{ name: 'Profile' }">
            View Profile
          </router-link>
        </li>
        <li
          v-if="getConfirmedUser"
          class="navbar-mobile-item"
        >
          <a @click.prevent="signOut">Sign Out</a>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      userDropdownOpen: false,
      mobileNavMenu: false
    }
  },
  computed: {
    ...mapGetters('auth', ['getConfirmedUser']),
    ...mapGetters('auth', ['authUser'])
  },
  created () {
    this.$router.beforeEach((to, from) => {
      this.mobileNavMenu = false
    })
  },
  methods: {
    signOut () {
      this.$store.dispatch('auth/signOut')
      this.$router.go('/')
    }
  }
}
</script>

<style scoped>
.menu-icon-holder {
  width: 20%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.menu-icon img {
  width: 30px;
  height: 30px;
}
</style>
