<template>
  <div v-show="!isLoading">
    <label for="Photo">
      <div v-if="isCameraOpen">
        <Camera
          ref="camera"
          :resolution="{ width: 800, height: 600 }"
          autoplay
        >
          <div id="circle" />
          <button
            class="snapshot-button"
            @click.prevent="takePhoto"
          >
            <img src="../assets/svg/camera.svg">
          </button>
          <button
            class="close-button"
            @click.prevent="toggleCamera"
          >
            <img src="../assets/svg/close.svg">
          </button>
        </Camera>
      </div>
    </label>
    <div
      v-if="!isPhotoTaken"
      class="upload-holder"
    >
      <button
        class="btn-upload"
        type="button"
        @click.prevent="toggleCamera"
      >
        <img
          src="/camera.svg"
          class="btn-icon"
          alt="Take Photo button"
        >
        Take Photo
      </button>
      <span>OR</span>
      <button
        class="btn-upload"
        type="button"
        @click="uploadImage"
      >
        <img
          src="/upload.svg"
          class="btn-icon"
          alt="Upload Image button"
        >
        Upload Image
        <input
          ref="inputImage"
          hidden="true"
          type="file"
          accept="image/*"
          @change="handleImageUpload"
        >
      </button>
    </div>
    <div
      v-if="isPhotoTaken"
      class="camera-shoot"
    >
      <div
        v-if="isPhotoTaken"
        class="preview-holder"
      >
        <h5>
          Preview of Image: <span
            v-if="retake"
            class="btn-retake"
            @click="clearPhoto"
          >Retake?</span>
        </h5>
        <canvas
          id="canvas"
          ref="canvas"
          width="800"
          height="600"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Camera from 'simple-vue-camera'
import { ref } from 'vue'
import useNotifications from '@/composables/useNotifications'

export default {
  components: { Camera },
  props: {
    asset: {
      required: false,
      type: Object,
      default: null
    },
    noPreview: {
      required: false,
      type: Boolean,
      default: false
    },
    retake: {
      required: false,
      type: Boolean,
      default: true
    },
    img: {
      required: false,
      type: String,
      default: null
    }
  },
  emits: ['blob-update', 'isTaken', 'retake'],
  setup () {
    // Setting up notification handler
    const { addNotification } = useNotifications()

    // Get a reference of the component
    const camera = ref(null)
    const imageUrl = null
    const loading = ref(false)

    // Use camera reference to call functions
    const snapshot = async () => {
      loading.value = true
      const blob = await camera.value?.snapshot(
        { width: 812, height: 375 },
        'image/jpeg',
        1
      )

      // To show the screenshot with an image tag, create a url
      loading.value = false
      return blob
    }

    return {
      camera,
      snapshot,
      imageUrl,
      loading,
      addNotification
    }
  },
  data () {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      assetCopy: { ...this.asset }
    }
  },
  computed: {
    imgBlob () {
      return this.img
    }
  },
  watch: {
    imgBlob (newValue) {
      if (newValue) {
        this.assetCopy.assetBlob = this.base64ToBlob(newValue)
        this.showPicture()
      }
    }
  },
  created () {
    if (this.asset?.assetBlob) {
      this.assetCopy.assetBlob = this.base64ToBlob(this.assetCopy.assetBlob)
      this.showPicture()
    }
  },
  methods: {
    createCameraElement () {
      this.isLoading = true

      const constraints = (window.constraints = {
        audio: false,
        video: true
      })
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false
          this.$refs.camera.srcObject = stream
        })
        .catch((error) => {
          this.isLoading = false
          alert(
            "May the browser didn't support or there is some errors.",
            error
          )
        })
    },
    clearPhoto () {
      this.$emit('retake', true)
      this.isPhotoTaken = false
    },
    showPicture () {
      this.isPhotoTaken = true
      this.isCameraOpen = false

      this.$emit('isTaken', !this.isCameraOpen)

      if (!this.noPreview) {
        // Wait for the canvas element to be fully rendered
        this.$nextTick(() => {
          const image = new Image()
          const context = this.$refs.canvas.getContext('2d')
          image.src = (this.assetCopy.assetBlob == null) ? '/user-placeholder.png' : window.URL.createObjectURL(this.assetCopy.assetBlob)
          image.onload = () => {
            context.drawImage(image, 0, 0, 800, 600)
          }
        })
      } else {
        this.$nextTick(() => {
          this.$refs.canvas.width = 0
          this.$refs.canvas.height = 0
        })
      }
    },
    async handleImageUpload (e) {
      this.assetCopy.assetBlob = e.target.files[0]
      this.showPicture()
      this.$emit('blob-update', {
        label: 'blob',
        data: {
          blob: await this.blobToBase64(this.assetCopy.assetBlob),
          snapshot: this.assetCopy.assetBlob
        }
      })
      this.addNotification({ message: 'Processing Image', timeout: 3000 })
      setTimeout(() => {
        this.addNotification({ message: 'Image added successfully', timeout: 3000 })
      }, 2000)
    },
    async takePhoto () {
      this.assetCopy.assetBlob = await this.snapshot()
      this.showPicture()
      this.$emit('blob-update', {
        label: 'blob',
        data: {
          blob: await this.blobToBase64(this.assetCopy.assetBlob),
          snapshot: this.assetCopy.assetBlob
        }
      })
      this.addNotification({ message: 'Processing Image', timeout: 3000 })
      setTimeout(() => {
        this.addNotification({ message: 'Image added successfully', timeout: 3000 })
      }, 2000)
    },
    uploadImage () {
      // Upload the asset
      this.$refs.inputImage.click()
    },
    toggleCamera () {
      this.isCameraOpen = !this.isCameraOpen
      this.$emit('isTaken', !this.isCameraOpen)
    },
    async blobToBase64 (blob) {
      return await new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
    },
    base64ToBlob (base64String, contentType = 'image/png') {
      let decodedData = base64String.replace('data:image/png;base64,', '')
      decodedData = base64String.replace('data:image/jpeg;base64,', '')
      decodedData = window.atob(decodedData)
      const byteArray = new Uint8Array(decodedData.length)

      for (let i = 0; i < decodedData.length; i++) {
        byteArray[i] = decodedData.charCodeAt(i)
      }

      const blob = new Blob([byteArray], { type: contentType })
      return blob
    },
    computed: {
      stop () {
        return this.isPhotoTaken ? 'Retake' : 'Take Photo'
      }
    }
  }
}
</script>

<style>
.upload-holder {
  padding: 20px;
  border: 1px solid #0d0d0d;
  background-color: #FFFFFF;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: nowrap;
}

.btn-upload {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.btn-icon {
  width: 37.5px;
  height: 37.5px;
  margin-bottom: 15px;
}

#circle {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 20%;
  background: #7d7d7d;
  opacity: 0.7;
  position:relative;
  top: 79%;
}
.snapshot-button {
  position:absolute;
  transition: .5s ease;
  top: 84%;
  left: 45%;
  width: 10%;
  height: 10%;
  min-width: 55px;
  min-height: 55px;
}

.close-button {
  position:absolute;
  transition: .5s ease;
  top: 84%;
  left: 80%;
  width: 10%;
  height: 10%;
  min-width: 55px;
  min-height: 55px;
}

#canvas {
  width: 100%;
}

.btn-retake {
  cursor: pointer;
  text-decoration: underline;
}
</style>
