import * as CryptoJS from 'crypto-js'
import * as openpgp from 'openpgp'

export function encrypt (state, data, id) {
  const key = state.auth?.authId || id
  if (!key) {
    console.error('Encryption key is required')
    return null
  }
  return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString()
}

export function decrypt (state, data, id) {
  const key = state.auth?.authId || id
  if (!key) {
    console.error('Decryption key is required')
    return null
  }
  return JSON.parse(CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8))
}

export async function encryptWithKey (data, publicKeyArmored) {
  if (!publicKeyArmored) {
    console.error('Encryption key is required')
    return null
  }
  const dataString = JSON.stringify(data)
  const publicKey = await openpgp.readKey({ armoredKey: publicKeyArmored })
  const encrypted = await openpgp.encrypt({
    message: await openpgp.createMessage({ text: dataString, format: 'text' }),
    encryptionKeys: publicKey
  })

  if (encrypted) {
    return encrypted
  }

  throw new Error('Error encrypting data')
}

export async function decryptWithKey (passphrase, encrypted, privateKeyArmored, publicKeyArmored) {
  try {
    if (!privateKeyArmored || !publicKeyArmored) {
      throw new Error('Private and public keys are required for decryption')
    }

    let privateKey = await openpgp.readPrivateKey({ armoredKey: privateKeyArmored })
    const publicKey = await openpgp.readKey({ armoredKey: publicKeyArmored })

    if (!privateKey.isDecrypted()) {
      privateKey = await openpgp.decryptKey({ privateKey, passphrase })
    }

    const message = await openpgp.readMessage({ armoredMessage: encrypted })
    const { data } = await openpgp.decrypt({
      message,
      decryptionKeys: privateKey,
      verificationKeys: publicKey,
      expectSigned: false
    })

    return data
  } catch (error) {
    console.error('Error decrypting data:', error)
    throw error
  }
}

export async function generatePair (id) {
  const { privateKey, publicKey } = await openpgp.generateKey({
    type: 'ecc',
    curve: 'curve25519',
    userIDs: [{ id }],
    passphrase: id
  })

  return {
    privateKey,
    publicKey
  }
}
