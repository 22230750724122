import { openDB } from 'idb'
import { v4 as uuidv4 } from 'uuid'
import DeviceDetector from 'device-detector-js'
import { stringify } from 'querystring'

import { fetchApiData, postApiData } from '@/helpers/api'
import { start, notificationRequest, notificationDecision } from '@/helpers/ws'

const tables = ['DOCUMENTS', 'USERS', 'REQUESTS', 'KEYS']

const dbPromise = (database, table) => {
  return openDB(database, getCurrentDBVersion(), {
    upgrade (db, oldVersion) {
      if (oldVersion < getCurrentDBVersion()) {
        tables.forEach(t => {
          if (!db.objectStoreNames.contains(t) && tables.includes(t)) {
            db.createObjectStore(t, { keyPath: 'id' })
          }
        })
      }
    }
  })
}

const getCurrentDBVersion = () => {
  return process.env.VUE_APP_INDEXED_DB_VERSION
}

export default {
  async unsubscribeAllSnapshots ({ state, commit }) {
    state.unsubscribes.forEach(unsubscribe => unsubscribe())
    commit('clearAllUnsubscribes')
  },
  clearItems ({ commit }, { modules = [] }) {
    commit('clearItems', { modules })
  },
  async get ({ state, commit }, { database, table, id }) {
    const db = await dbPromise(database, table)
    const tx = db.transaction(table, 'readonly')
    const store = tx.objectStore(table)
    return await store.get(id)
  },
  async getByUserId ({ state, commit }, { database, table, id, userId }) {
    const db = await dbPromise(database, table)
    const tx = db.transaction(table, 'readonly')
    const store = tx.objectStore(table)
    const keys = await store.getAll()
    let found = null
    keys.forEach(key => {
      if (key.userId === userId && key.id === id) {
        found = key
      }
    })
    return found
  },
  async gets ({ state, commit }, { database, table }) {
    const db = await dbPromise(database, table)
    const tx = db.transaction(table, 'readonly')
    const store = tx.objectStore(table)
    return await store.getAll()
  },
  async add ({ state, commit }, { database, table, record }) {
    const db = await dbPromise(database, table)
    const tx = db.transaction(table, 'readwrite')
    const store = tx.objectStore(table)
    await store.put(record)
    await tx.done
  },
  async update ({ state, commit }, { database, table, id, record }) {
    const db = await dbPromise(database, table)
    const tx = db.transaction(table, 'readwrite')
    const store = tx.objectStore(table)
    if (await store.get(id)) {
      await store.put(record)
    }
    return store.get(id)
  },
  async del ({ state, commit }, { database, table, id }) {
    const db = await dbPromise(database, table)
    const tx = db.transaction(table, 'readwrite')
    const store = tx.objectStore(table)
    await store.delete(id)
  },
  async fetchData ({ state, commit, rootGetters }, { stream }) {
    return await fetchApiData(`api/${stream}`)
  },
  async fetchDataWithParams ({ state, commit, rootGetters }, { stream, params }) {
    return await fetchApiData(`${stream}?${stringify(params)}`)
  },
  async fetchDataWithURL ({ state, commit, rootGetters }, { stream, params }) {
    return await fetchApiData(`${stream}/${params}`)
  },
  async createData ({ state, commit, rootGetters }, { stream, body }) {
    return await postApiData(stream, body)
  },
  startWebSocket (_, { callbackError, callbackRequest, callbackResponse, callbackWebsocketClosed, deviceID, clientID }) {
    start(callbackError, callbackRequest, callbackResponse, callbackWebsocketClosed, deviceID, clientID)
  },
  async sendNotificationRequest (_, { requestID, clientID }) {
    return await notificationRequest(requestID, clientID)
  },
  async sendNotificationDecision (_, { requestID, clientID, approval }) {
    return await notificationDecision(requestID, clientID, approval)
  },
  async generateDeviceId () {
    const deviceId = uuidv4()
    const deviceDetector = new DeviceDetector()
    const deviceInfo = deviceDetector.parse(navigator.userAgent)
    const os = deviceInfo.os.name
    const screenSize = `${window.innerWidth}x${window.innerHeight}`
    const uniqueDeviceID = `${os}-${screenSize}-${deviceId}`
    return uniqueDeviceID
  },
  async getPublicKey ({ _ }, id) {
    return await fetchApiData(`api/public/key/${id}`)
  }
}
