<template>
  <div class="sideNavContainer">
    <nav
      class="[sideNav, isOpen ? styles.open : '']"
      :style="{ display: displayType }"
    >
      <ul class="menuList">
        <li
          v-for="(item, index) in menuItems"
          :key="index"
          class="menuItem"
          @click="navigateTo(item.route)"
        >
          <font-awesome-icon
            :icon="item.icon"
            class="menuIcon"
          />
          <span class="menuTitle">{{ item.title }}</span>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script src="./SideNav.script.js"></script>
<style scoped src="./SideNav.style.css"></style>
