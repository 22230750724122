import { createStore } from 'vuex'
import getters from '@/store/getters'
import actions from '@/store/actions'
import mutations from '@/store/mutations'
import categories from './modules/categories'
import documentsLs from './modules/documents-ls'
import documentTypes from './modules/documentTypes'
import external from './modules/external'
import identifiers from './modules/identifiers'
import requests from './modules/requests'
import users from './modules/users'
import verifications from './modules/verifications'
import auth from './modules/auth'
export default createStore({
  modules: {
    categories,
    documentsLs,
    documentTypes,
    identifiers,
    requests,
    users,
    verifications,
    auth,
    external
  },
  state: {
    unsubscribes: []
  },
  getters,
  actions,
  mutations
})
