import { encryptWithKey, decryptWithKey, decrypt } from '@/helpers/encrypt'

const STREAM = 'api/request'

const DB_NAME = 'PROOFOFID'
const TABLE = 'KEYS'
const KEY_NAMES = {
  privateKey: 'PRIVATE_KEY',
  publicKey: 'PUBLIC_KEY'
}

export default {
  namespaced: true,
  state: {
    items: [],
    requests: []
  },
  getters: {},
  mutations: {
    setNewRequests (state, newRequests) {
      state.requests = newRequests
    }
  },
  actions: {
    async fetchRequestById ({ dispatch }, reqId) {
      return await dispatch('fetchDataWithURL', { stream: 'api/request', params: reqId }, { root: true })
    },
    async fetchRequestsByDate ({ rootState, dispatch }, { startDate, endDate }) {
      return await dispatch('fetchDataWithParams', { stream: 'api/request/metrics/by-requestor', params: { responder_id: rootState.auth.authId, start_date: startDate, end_date: endDate } }, { root: true })
    },
    async fetchRequests ({ rootState, dispatch }) {
      return await dispatch('fetchDataWithParams', { stream: 'api/request/list', params: { responder_id: rootState.auth.authId, page: '1' } }, { root: true })
    },
    async fetchRequestsByRequestorID ({ rootState, dispatch }, { page, limit, order }) {
      return await dispatch('fetchDataWithParams', { stream: 'api/request/list/by-requestor', params: { requestor_id: rootState.auth.authId, page, limit, order } }, { root: true })
    },
    async createRequest ({ rootState, dispatch }) {
      const request = {
        responderId: rootState.auth.authId,
        deviceId: localStorage.getItem('DEVICE_ID')
      }
      return await dispatch('createData', { stream: STREAM + '/create', body: request }, { root: true })
    },
    async startNotificationListener ({ rootState, dispatch }, { callbackError, callbackRequest, callbackResponse, callbackWebsocketClosed }) {
      const client = rootState.auth.authId
      if (client) {
        return await dispatch('startWebSocket', {
          callbackError,
          callbackRequest,
          callbackResponse,
          callbackWebsocketClosed,
          deviceID: localStorage.getItem('DEVICE_ID'),
          clientID: client
        }, { root: true })
      }
    },
    async sendNotification ({ rootState, dispatch }, request) {
      return await dispatch('sendNotificationRequest', { requestID: request, clientID: rootState.auth.authId }, { root: true })
    },
    async sendDecision ({ rootState, dispatch }, { request, approval }) {
      return await dispatch('sendNotificationDecision', { requestID: request.id, clientID: rootState.auth.authId, approval }, { root: true })
    },
    async transferData ({ rootState, dispatch }, { publicKeyID, requestId }) {
      const key = await dispatch('getPublicKey', publicKeyID, { root: true })
      const documentsRaw = await dispatch('documentsLs/getItems', null, { root: true })
      const ecryptedData = await encryptWithKey(documentsRaw, key.publicKey)
      const delivery = {
        requestId,
        deliveryData: ecryptedData
      }
      return await dispatch('createData', { stream: 'api/delivery/create', body: delivery }, { root: true })
    },
    async decryptDataTransferred ({ rootState, dispatch }, requestId) {
      const privateEncryptedKey = await dispatch('getByUserId', { database: DB_NAME, table: TABLE, id: KEY_NAMES.privateKey, userId: rootState.auth.authId }, { root: true })
      const publicEncryptedKey = await dispatch('getByUserId', { database: DB_NAME, table: TABLE, id: KEY_NAMES.publicKey, userId: rootState.auth.authId }, { root: true })
      const privateKey = await decrypt(rootState, privateEncryptedKey.data)
      const publicKey = await decrypt(rootState, publicEncryptedKey.data)
      const delivery = await dispatch('fetchDataWithURL', { stream: 'api/delivery', params: requestId }, { root: true })
      if (delivery.deliveryData === '') {
        return ''
      }
      return await decryptWithKey(rootState.auth.authId, delivery.deliveryData, privateKey, publicKey)
    }
  }
}
