<template>
  <div :class="['app', layoutClass]">
    <AppHead>
      <title>Proof of ID | Secure Identity Verification and Sharing</title>
      <meta
        content="Proof Of ID offers the best digital identity verification and sharing platform to protect, control, and share identities securely."
        name="description"
      >
      <meta
        content="Proof of ID | Secure Identity Verification and Sharing"
        property="og:title"
      >
      <meta
        content="Proof Of ID offers the best digital identity verification and sharing platform to protect, control, and share identities securely."
        property="og:description"
      >
      <link
        href="favicon-small.png"
        rel="shortcut icon"
        type="image/x-icon"
      >
      <link
        href="favicon-large.png"
        rel="apple-touch-icon"
      >
      <link
        rel="icon"
        href="/favicon-small.png"
      >
    </AppHead>
    <the-navbar v-if="getConfirmedUser" />
    <div class="main-content">
      <SideNav
        v-if="getConfirmedUser"
        :is-open="true"
        :menu-items="menuItems"
      />
      <div class="content">
        <router-view
          v-show="showPage"
          :key="`${$route.path}${JSON.stringify($route.query)}`"
          @ready="onPageReady"
          @not-ready="loadPage"
        />
        <AppSpinner v-show="!showPage" />
      </div>
    </div>
  </div>
  <AppNotifications />
</template>

<script>
import { mapGetters } from 'vuex'
import AppNotifications from '@/components/AppNotifications'
import NProgress from 'nprogress'
import TheNavbar from '@/components/TheNavbar'
import SideNav from '@/components/navigation/side/SideNav'

export default {
  name: 'App',
  components: { AppNotifications, TheNavbar, SideNav },
  data () {
    return {
      showPage: false,
      showRequestModal: false,
      currentRequest: {
        requesterName: 'Requester Name',
        identifiers: ['Identifier 1', 'Identifier 2'],
        requestedAt: new Date(),
        isActive: true,
        isApproved: false
      },
      timeoutId: null
    }
  },
  computed: {
    ...mapGetters('auth', ['getConfirmedUser']),
    data () {
      // Access the requests from the store's state
      return this.$store.state.requests
    },
    layoutClass () {
      return this.$route.meta.layoutClass || ''
    }
  },
  async created () {
    NProgress.configure({
      speed: 200,
      showSpinner: false
    })
    this.$router.beforeEach(() => {
      this.showPage = false
      NProgress.start()
    })
  },
  mounted () {
    // Set initial timeout
    this.resetTimeout()

    // Add event listeners to reset the timeout on user interaction
    document.addEventListener('mousemove', this.resetTimeout)
    document.addEventListener('click', this.resetTimeout)

    // Set a max session of 5 mins
    const timeoutDuration = process.env.VUE_APP_SESSION_TIMEOUT ? process.env.VUE_APP_SESSION_TIMEOUT : 300000
    setTimeout(() => {
      document.cookie = ''
      sessionStorage.clear()
      this.$router.go('/')
    }, timeoutDuration)
  },
  methods: {
    onPageReady () {
      this.showPage = true
      NProgress.done()
    },
    loadPage () {
      this.showPage = false
      NProgress.start()
    },
    resetTimeout () {
      // Clear existing timeout
      clearTimeout(this.timeoutId)
      // Set a max session of 5 mins
      const timeoutDuration = process.env.VUE_APP_SESSION_TIMEOUT ? process.env.VUE_APP_SESSION_TIMEOUT : 300000
      // Set a new timeout
      this.timeoutId = setTimeout(() => {
        document.cookie = ''
        sessionStorage.clear()
        this.$router.go('/')
      }, timeoutDuration)
    }
  }
}
</script>

<style>
@import "assets/style.css";
@import "assets/modals.css";
@import "~nprogress/nprogress.css";
#nprogress .bar {
  background: #57ad8d !important;
}

.main-content {
  display: flex;
  flex: 1;
  position: relative;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.content {
  flex: 1;
  padding: 20px;
}

.side-nav {
  width: 250px; /* Adjust this value based on the width of your SideNav */
}

.main-content:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('https://cdn.prod.website-files.com/65f8f1d0dc04cb6fb982697d/668d77b036fa3b551b413242_dot3.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.3; /* Make the image a bit transparent */
  z-index: 1;
}

.main-content::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, var(--gradient-start), var(--gradient-end)) no-repeat;
  opacity: 0.01; /* Make the image a bit transparent */
  z-index: 2;
}

.main-content > * {
  position: relative;
  z-index: 3;
}
</style>
