import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faHome, faUsers, faCodePullRequest, faSitemap, faGear, faChartLine } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'SideNav',
  components: {
    FontAwesomeIcon
  },
  data () {
    return {
      isOpen: false,
      displayType: 'block',
      menuItems: [
        { title: 'Home', icon: faHome, route: '/' },
        { title: 'Dashboard', icon: faChartLine, route: '/dashboard' },
        { title: 'Requests', icon: faCodePullRequest, route: '/requests' },
        { title: 'Organizations', icon: faSitemap, route: '/organizations' },
        { title: 'Users', icon: faUsers, route: '/users' },
        { title: 'Settings', icon: faGear, route: '/settings' }
      ]
    }
  },
  methods: {
    navigateTo (route) {
      this.$router.push(route)
    }
  }
}
