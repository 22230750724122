import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '@/pages/Home'),
    meta: { requiresAuth: true }
  },
  {
    path: '/category/selection',
    name: 'CategorySelection',
    component: () => import(/* webpackChunkName: "CategorySelection" */ '@/pages/CategorySelection'),
    meta: { requiresAuth: true }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "Profile" */'@/pages/Profile'),
    meta: { toTop: true, smoothScroll: true, requiresAuth: true }
  },
  {
    path: '/me/edit',
    name: 'ProfileEdit',
    component: () => import(/* webpackChunkName: "ProfileEdit" */'@/pages/Profile'),
    props: { edit: true },
    meta: { requiresAuth: true }
  },
  {
    path: '/history',
    name: 'History',
    component: () => import(/* webpackChunkName: "History" */'@/pages/HistoryShow'),
    props: { edit: true },
    meta: { requiresAuth: true }
  },
  {
    path: '/requests',
    name: 'Requests',
    component: () => import(/* webpackChunkName: "Requests" */'@/pages/requests/Requests.vue'),
    props: { edit: true },
    meta: { requiresAuth: true }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "Users" */'@/pages/nopermissions/NoPermissions.vue'),
    props: { edit: true },
    meta: { requiresAuth: true }
  },
  {
    path: '/organizations',
    name: 'Organizations',
    component: () => import(/* webpackChunkName: "Organizations" */'@/pages/construction/Construction.vue'),
    props: { edit: true },
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "Settings" */'@/pages/construction/Construction.vue'),
    props: { edit: true },
    meta: { requiresAuth: true }
  },
  {
    path: '/category/:id',
    name: 'Category',
    component: () => import(/* webpackChunkName: "Category" */'@/pages/Category'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/document/:id',
    name: 'DocumentShow',
    component: () => import(/* webpackChunkName: "DocumentShow" */'@/pages/DocumentShow'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */'@/pages/Dashboard'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/verification/questions/:documentId?',
    name: 'VerificationQuestions',
    component: () => import(/* webpackChunkName: "VerificationQuestions" */'@/pages/VerificationQuestions'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/category/:categoryId/document/create',
    name: 'DocumentCreate',
    component: () => import(/* webpackChunkName: "DocumentCreate" */'@/pages/DocumentCreate'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/document/:id/delete',
    name: 'DocumentDelete',
    component: () => import(/* webpackChunkName: "DocumentDelete" */'@/pages/DocumentDelete'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/error/:id',
    name: 'Error',
    component: () => import(/* webpackChunkName: "Error" */'@/pages/Error'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/auth/login',
    name: 'AuthLogin',
    component: () => import(/* webpackChunkName: "AuthLogin" */'@/pages/auth/login/Login'),
    meta: { requiresGuest: true, layoutClass: 'login-page' }
  },
  {
    path: '/auth/mfa',
    name: 'AuthMfa',
    component: () => import(/* webpackChunkName: "AuthMfa" */'@/pages/auth/mfa/MFA'),
    meta: { requiresGuest: true, layoutClass: 'mfa-page' }
  },
  {
    path: '/logout',
    name: 'SignOut',
    async beforeEnter (to, from) {
      await store.dispatch('auth/signOut')
      return { name: 'Home' }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "NotFound" */'@/pages/NotFound')
  }
]
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to) {
    const scroll = {}
    if (to.meta.toTop) scroll.top = 0
    if (to.meta.smoothScroll) scroll.behavior = 'smooth'
    return scroll
  }
})
router.afterEach(() => {
  store.dispatch('clearItems', { modules: ['categories', 'documentsLs', 'requests', 'identifiers'] })
})

router.beforeEach(async (to, from, next) => {
  await store.dispatch('auth/initAuthentication')
  store.dispatch('unsubscribeAllSnapshots')

  const isAuthenticated = !!store.state.auth.authId || window.sessionStorage.getItem('ACCESS_TOKEN')
  const user = isAuthenticated ? await store.dispatch('users/get') : null
  const isSignedIn = store.state.auth.accessToken || window.sessionStorage.getItem('ACCESS_TOKEN')
  const isUserVerified = user?.verified?.status
  const toCompleteRegistration = !isUserVerified && to.name !== 'RegistrationCompletion'
  const toResetMFA = from.path === '/profile' && to.path === '/mfa'
  const hasDocuments = (await store.dispatch('documentsLs/getItems')).length > 0

  if (to.meta.requiresAuth) {
    if (!isAuthenticated && !isSignedIn) {
      return next({ name: 'AuthLogin', query: { redirectTo: to.path } })
    }
    if (isUserVerified === 'declined' && to.name !== 'Error' && from.name !== 'Error') {
      return next({ name: 'Error', params: { id: 'Not generated' } })
    }
    if (!hasDocuments && isAuthenticated && isUserVerified === true && !toCompleteRegistration && !toResetMFA && isSignedIn && to.name !== 'CategorySelection' && to.name !== 'DocumentCreate') {
      return next({ name: 'CategorySelection' })
    }
    if (isUserVerified === 'pending' && to.name !== 'VerificationQuestions') {
      return next({ name: 'VerificationQuestions' })
    }
  }

  if (to.meta.requiresGuest) {
    if (isAuthenticated && isSignedIn) {
      if (toResetMFA) {
        if (to.path !== '/mfa') {
          return next({ name: 'MFASetup' })
        } else {
          return next()
        }
      }
      return next({ name: 'Home' })
    }
  }

  next()
})

export default router
