import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import FontAwesome from '@/plugins/FontAwesome'
import ClickOutsideDirective from '@/plugins/ClickOutsideDirective'
import PageScrollDirective from '@/plugins/PageScrollDirective'
// import Vue3Pagination from '@/plugins/Vue3Pagination'
import VeeValidatePlugin from '@/plugins/VeeValidatePlugin'
import vuetify from '@/plugins/vuetify'
import { createHead } from '@vueuse/head'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue3-qrcode-reader'

// Define the feature flag
window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false

const forumApp = createApp(App)
forumApp.use(router)
forumApp.use(store)
forumApp.use(FontAwesome)
forumApp.use(ClickOutsideDirective)
forumApp.use(PageScrollDirective)
// forumApp.use(Vue3Pagination)
forumApp.use(VeeValidatePlugin)
forumApp.use(createHead())

forumApp.use(vuetify)

forumApp.component('QrcodeStream', QrcodeStream)
forumApp.component('QrcodeDropZone', QrcodeDropZone)
forumApp.component('QrcodeCapture', QrcodeCapture)

const requireComponent = require.context('./components', true, /App[A-Z]\w+\.(vue|js)$/)
requireComponent.keys().forEach(function (fileName) {
  let baseComponentConfig = requireComponent(fileName)
  baseComponentConfig = baseComponentConfig.default || baseComponentConfig
  const baseComponentName = baseComponentConfig.name || (
    fileName
      .replace(/^.+\//, '')
      .replace(/\.\w+$/, '')
  )
  forumApp.component(baseComponentName, baseComponentConfig)
})

forumApp.mount('#app')
